<template>
  <div ref="tutorial" class="tutorial">
    <div class="container">
      <div class="tutorial-item active">
        <div class="row justify-content-center">
          <div class="col-6 col-md-4 col-lg-3">
            <div class="icon">
              <Lottie :options="slideOptions" v-on:animCreated="handleSlide" />
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col-md-6 text-center">
            <h4>Slide</h4>

            <p
              data-en="Move to see the other kind."
              data-id="Geser untuk melihat lainnya."
              class="mt-2 mb-4"
            >
              Move to see the other kind.
            </p>

            <a @click.prevent="nextTutorial" class="btn btn-outline-white px-5">
              OK
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Plugins
import Lottie from "@/components/plugins/Lottie.vue";

// Animations
import * as slide from "../../../public/assets/animations/slide.json";

export default {
  name: "TutorialSlide",
  components: {
    Lottie,
  },
  props: {
    nameTutorial: String,
  },
  data() {
    return {
      slideOptions: {
        animationData: slide.default,
        loop: true, // Optional
        autoplay: true, // Optional
      },
    };
  },
  methods: {
    handleSlide(anim) {
      this.slide = anim;
    },
    nextTutorial() {
      const currentItem = this.$refs.tutorial.querySelector(".active");
      const nextSibling = currentItem.nextSibling;

      if (nextSibling) {
        currentItem.classList.remove("active");
        nextSibling.classList.add("active");
      } else {
        this.$refs.tutorial.classList.remove("active");
        this.$store.dispatch("tutorial/setTutorial", {
          key: this.nameTutorial,
          value: true,
        });
      }
    },
  },
};
</script>

<style>
</style>